/**
 * Created by hoa on 02/10/2017.
 */


define(['app', 'valumsFileUploader', 'siteObj'], (app, valums, siteObj) => {
  var professionalForm = function() {
    var component = {};

    var _config = {
      uploadUrl: '[data-js-element=professionalForm_uploadUrl]',
      fileInput: '[data-js-element=professionalForm_inputFile]',
      uploader: '[data-js-element=professionalForm_uploader]',
      authToken: '.authToken',
      fileToken: '.fileToken',
      level: '#professionalLevel',
      requirements: '.professionalForm_requirements',
      exitButton: '.professionalForm_requirementsExit',
      uploadLabels: '.professionalForm_formUploadLabel'
    };

    var _init = function(element) {
      component.element = element;
      component.uploadURL = element.querySelector(component.config.uploadUrl);
      component.fileInput = element.querySelectorAll(component.config.fileInput);
      component.level = element.querySelector(component.config.level);
      component.requirements = element.querySelector(component.config.requirements);
      component.exitButton = element.querySelector(component.config.exitButton);
      component.uploaderElements = Array.from(component.fileInput);
      component.uploaders = component.uploaderElements.map(component.renderInput);
      component.uploadLabelsElements = element.querySelectorAll(component.config.uploadLabels);
      component.uploadLabels = Array.from(component.uploadLabelsElements);

      component.level.addEventListener('change', component.onchange);
      component.exitButton.addEventListener('click', component.close);
    };

    var _renderInput = function(el) {
      var authToken = el.querySelector(component.config.authToken).getAttribute('value');
      var tokenEl = el.querySelector(component.config.fileToken);
      var uploader = el.querySelector(component.config.uploader);

      el.style.display = 'none';

      return new valums.FileUploader({
        element: uploader,
        multiple: false,
        inputName: '',
        action: component.uploadURL.getAttribute('value'),
        sizeLimit: 1024 * 1024 * 2,
        customHeaders: {
          uploadToken: authToken
        },
        onComplete: function(id, fileName, response) {
          if (response.successful) {
            tokenEl.setAttribute('value', response.token);
          }
        }
      });
    };

    var _onchange = function(ev) {
      var option = component.level.querySelector('option[value="' + component.level.value + '"]');
      var reqs = option.getAttribute('data-requirements');

      // The switch is only relevant to Illamasqua which is currently the only site that uses the professional form
      switch (component.level.value) {
        case 'STUDENT':
          component.uploaderElements[0].style.display = 'block';
          component.uploaderElements[1].style.display = 'block';
          component.uploaderElements[2].style.display = 'none';
          component.uploadLabels[0].style.display = 'block';
          component.uploadLabels[1].style.display = 'block';
          component.uploadLabels[2].style.display = 'none';
          break;
        case 'WORKING':
          component.uploaderElements[0].style.display = 'block';
          component.uploaderElements[1].style.display = 'block';
          component.uploaderElements[2].style.display = 'block';
          component.uploadLabels[0].style.display = 'block';
          component.uploadLabels[1].style.display = 'block';
          component.uploadLabels[2].style.display = 'block';
          break;
        default:
          component.uploaderElements[0].style.display = 'none';
          component.uploaderElements[1].style.display = 'none';
          component.uploaderElements[2].style.display = 'none';
          component.uploadLabels[0].style.display = 'none';
          component.uploadLabels[1].style.display = 'none';
          component.uploadLabels[2].style.display = 'none';
      }
      component.requirements.innerHTML = reqs;
      component.requirements.parentElement.style.display = 'block';
    };

    var _close = function(ev) {
      component.requirements.parentElement.style.display = 'none';
    };

    component.config = _config;
    component.init = _init;
    component.renderInput = _renderInput;
    component.onchange = _onchange;
    component.close = _close;
    component.valums = valums;

    return component;
  };

  return professionalForm;
});
